<template>
     <v-dialog v-model="dialog" persistent max-width="50%">
     <!-- <app-loader v-model="isLoading"></app-loader> -->
      <template v-slot:activator="{ on }">
          <app-add-button v-bind:module="'currency'"></app-add-button>
        </template>
         <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
          >
           <v-card title>
            <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
           <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <!-- <v-text-field v-model="editedItem.cur_code" required label="Currency Code"></v-text-field> -->
                    <v-text-field 
                        v-model="editedItem.cur_code"
                        :counter="3"
                        label="Currency Code"
                        required
                        maxlength=3
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <!-- <v-text-field v-model="editedItem.cur_desc" required label="Currency Description"></v-text-field> -->
                    <v-text-field 
                        v-model="editedItem.cur_desc"
                        :counter="250"
                        label="Description"
                        required
                        maxlength=250
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
              <v-btn color="red" text @click="close">Cancel</v-btn>
            </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		search: null,
		isvalid: true,
		cur_codeRules: [
			v => !!v || 'Currency Code is required.',
			v => (v && v.length <= 7) || 'FCurrency Coder must be less than 2 character',
		],
		cur_descRules: [
			v => !!v || 'Currency description is required.',
			v => (v && v.length <= 250) || 'Currency description must be less than 250 characters',
		],
	}),

	computed: {
		...mapGetters({
			currencies: 'currency/currencies',
			currencyCode: 'currency/currencyCode',
			currencyDesc: 'currency/currencyDesc',
			editedItem: 'currency/editedItem',
			defaultItem: 'currency/defaultItem',
			dialog: 'currency/dialog',
			editedIndex: 'currency/editedIndex',
			formTitle: 'currency/formTitle',
			valid: 'currency/valid',
			isLoading: 'app/isLoading'

		})
	}, 
	methods: {
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('currency/setValid',true);
				this.$store.dispatch('currency/setDialog',false);
				this.$store.dispatch('currency/setEditedIndex','-1');
				this.isvalid = false;
			}, 2000);
		},
		save () {
			this.$store.dispatch('currency/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			if(this.valid){
				if (this.editedIndex > -1) {
					this.$store.dispatch('currency/updateCurrency',this.editedItem);
				} else {
					this.$store.dispatch('currency/saveCurrency',this.editedItem);
				}
				setTimeout(() => {
					this.close();
				}, 
				3000);
			}
		},
      
	}
};
</script>